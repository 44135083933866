
import { Component, Prop, Vue } from 'vue-property-decorator';
import TableWithActions2 from '@/components/tableWithActions/TableWithActions2.vue';
import CellWithSubtitle from '@/components/tableWithActions/CellWithSubtitle.vue';
import {
  CutCurrentWorkloadDataMessage,
  CutCurrentWorkloadDataPlanInfo,
  CutDeviceCurrentWorkloadData,
  MessageLevel,
} from '@/models/Charts/chartsData';
import { isEmpty } from '@/utils/misc';
import { formatDuration } from '@/utils/dates';
import { addDataTestIdAttributeToTh, formatDateWithTime } from '@/utils/table';
import i18n from '@/i18n';
import TableActionButton2 from '../tableWithActions/TableActionButton2.vue';
import { BubbleType } from '../ActionBubble.vue';
import CutCellControlActiveMessages from './CutCellControlActiveMessages.vue';
import { modalService } from '@/library-services/modal.service';
import TableActionsList from '../tableWithActions/TableActionsList.vue';
import TableColumnActionsWrapper from '../tableWithActions/TableColumnActionsWrapper.vue';
import GenericKeyCopyModal from '../GenericKeyCopyModal.vue';
import { lengthUnit, millimetersToCustomerUnit } from "@/utils/measurement";
import { getStateCss } from '@/utils/color';
import { MachineState } from '@/models/deviceStateData';
import { decimalFormatter, formatPercentage } from '@/utils/number';

/**
 * Table listing devices with their current cutting plans.
 *
 * By default, just lists devices with some information. The user can expand the
 * rows to show additional information about the cutting plans currently in
 * the workload of the device, like the materials and their properties, the plan
 * progress, etc.
 */
@Component({
  components: {
    CellWithSubtitle,
    TableActionButton2,
    TableWithActions2,
    TableActionsList,
    TableColumnActionsWrapper,
  },
  methods: {
    millimetersToCustomerUnit,
    formatPercentage,
    addDataTestIdAttributeToTh,
    decimalFormatter,
    formatDuration,
    formatDateWithTime,
    isEmpty,
  },
})
export default class CutCurrentWorkloadTable extends Vue {
  @Prop({ required: true })
  private data!: CutDeviceCurrentWorkloadData[] | null;

  @Prop({ required: true })
  private loading!: boolean;

  private get coalescedData(): CutDeviceCurrentWorkloadData[] {
    return this.data ?? [];
  }

  private calculateTotalEstimatedCuttingTime(workloadData: CutDeviceCurrentWorkloadData): number {
    return workloadData.cuttingPlanInfo.reduce(
      (totalDuration, currentPlanItem) =>
        totalDuration + currentPlanItem.estimatedCuttingTimeSeconds,
      0,
    );
  }

  private statusStyle(status: MachineState) {
    return getStateCss(status);
  }

  private get measurementUnit(): string {
    return lengthUnit();
  }

  private formatDimensions(width: number, height: number): string {
    return `${Math.round(millimetersToCustomerUnit(width))}`
      + ` x ${Math.round(millimetersToCustomerUnit(height))}`;
  }

  private openMessageModal(row: CutDeviceCurrentWorkloadData) {
    modalService.open({
      parent: this,
      component: CutCellControlActiveMessages,
      width: 900,
      props: {
        messages: row.currentMessages,
      },
    });
  }

  private openNcPathModal(detail: CutCurrentWorkloadDataPlanInfo) {
    modalService.open({
      parent: this,
      component: GenericKeyCopyModal,
      canCancel: ['outside'],
      props: {
        value: detail.ncFilePath,
        title: i18n.t('cut_current_workload_table.actions.nc_path_title'),
      },
    });
  }

  private getBubbleContent(messages: CutCurrentWorkloadDataMessage[]): string | undefined {
    if (isEmpty(messages)) {
      return undefined;
    }
    return messages.length.toString();
  }

  private getBubbleType(messages: CutCurrentWorkloadDataMessage[]): BubbleType | undefined {
    if (isEmpty(messages)) {
      return undefined;
    }
    if (messages.some((x) => x.messageLevel === MessageLevel.Error)) {
      return 'is-danger';
    }
    if (messages.some((x) => x.messageLevel === MessageLevel.Warning)) {
      return 'is-warning';
    }
    return 'is-info';
  }
}
